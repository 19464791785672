.author-info h4 {
    margin-bottom:0px;
    font-size:17px;
}
.author-info {
    text-align: center;
    overflow: hidden;
}

.author-page-title {
    margin:50px;
    margin-top: 30px;
    margin-bottom: 70px;
}
.author-page-title h1 {
    font-size:60px;
}
.author-page-title p {
    font-size:35px;
}

.author-pic {
    width: 160px;
    height: 160px;
    border-radius: 10px;
    margin-bottom: 5px;
    overflow: hidden;
}

.zoom {
    transform: scale(1.2);
}

.author-title {
    display:block;
    font-size:17px;
}
.row {
    margin-bottom: 20px;
}
/**
COURSE PAGE
**/

/**
CIOS
**/

@keyframes fadeInLeft {
  0% {
    opacity: 0; 
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1; 
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}

.view-more-button {
  display: flex;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  text-decoration: underline;
}

.dark-mode .link {
  color: #BDCBFF;
}

.light-mode .link {
  color: #0065D1;
}

.view-more-button:hover {
  text-decoration: none;
}

.cios-container {
  margin-top: 1em;
  position: relative;
  animation: fadeInDetails 0.5s ease;
  display: flex;
  position: relative;
  justify-content: right;
}

.cios-table-entry {
  font-size: 14px;
}

.cios-table-element {
  display: flex;
  position: relative;
  width: fit-content;
  height: fit-content;
  gap: 0.5em;
  flex-direction: row;
  margin-left: auto;
}

.cios-view-more {
  opacity: 1;
}

.cios-view-more.hidden {
  opacity: 0;
  animation: fadeOutRight 0.5s ease;
  display: none;
}

.cios-view-more.visible {
  opacity: 1;
  animation: fadeInLeft 0.5s ease;
}

.info-icon {
  cursor: pointer;
  position: relative;
  font-size: 16px;
  bottom: 5px;
}

.grade-button {
  display: block;
  position: relative;
  width: fit-content;
  padding: 0.5em 1em;
  background-color: #606060;
  color: #fff;
  border: none;
  border-radius: 1em;
}

.grade-button:hover {
  background-color: #adadad;
  cursor: pointer;
}

.chart-victory {
  animation: fadeInDetails 0.5s ease;
  margin: 0 auto;
}

/**
AUXILIARY
**/

.dark-mode .text-change {
  color: #fff;
}

.light-mode .text-change {
  color: #000;
}

* {
  font-family: "Open Sans",Calibri,Candara,Arial,sans-serif;
}

.custom-input {
  background-color: #5d5d5d; 
}

.page-header {
  padding: 12px;
  padding-top: 1.5rem;
}

.lite-header {
  font-size: 14px;
  text-align: center;
}

@keyframes fadeInDetails {
  0% {
    opacity: 0; 
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.center-table {
  margin: 0 auto !important;
  float: none !important;
  animation: fadeInDetails 0.5s ease;
}

.filter-center {
  float: right !important;
}

.table-header {
  width: auto;
  font-size: 14px;
  display: flex;
  max-width: 700px; /* 600 for single column */
}

.dark-mode .course-header a {
  color: #BDCBFF !important;
} 

.dark-mode .prof-head a {
  color: #BDCBFF !important;
}

.light-mode .course-header a {
  color: #0065D1;
}

.light-mode .prof-head a {
  color: #0065D1;
}

.light-mode .modal-content {
  background-color: #ffffff;
}

.dark-mode .modal-content {
  background-color: #272727;
}

.dark-mode .modal-content button {
  color: #ffffff;
}

.btn-primary {
  background-color: #3F6BBE    !important;
  border-color: #3F6BBE    !important;
}

.dark-mode .btn-primary {
  background-color: #82A1DC    !important;
  border-color: #82A1DC !important;
}

.table-header th {
    text-align: center !important;
    font-weight: 700;
    color: #333333;
    border-top: 0 !important;
}

.dark-mode .table-header th {
  border-color: #606060 !important;
}

body.light-mode {
  color: #333;
}

body.dark-mode .table-header th {
  color: #B3B3B3;
}

.table-header td {
  text-align: center !important;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
  border-top: 1px solid #dddddd;
}

body.dark-mode .table-header td {
  background-color:rgb(61, 61, 61);
  transition: background-color 0.3s ease;
  color: white;
}

.headerTable {
  font-size: 14px;
}

/* .order {
  display: flex;
  flex-direction: column-reverse;
} */
.order {
 /* display: flex;
 flex-direction: column-reverse; */
 float: right;
}

.caret {
  display: inline-block; 
  width: 0;
  height: 0;
  /* margin-left: 2px;  */
  margin: 0 !important;
  vertical-align: middle;
  border-top: 6px dashed;
  border-top: 6px solid \9;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  margin: 0 !important;
  border-bottom: 8px dashed;
  border-bottom: 8px solid \9;
  content: "";
}

/* .dropdown {
  height: 40px !important;
}

.dropup {
  height: 40px !important;
} */
